import image1 from "../assets/chirag.png";
import image2 from "../assets/abhay.jpg";
import image3 from "../assets/shivansh.jpg";
import image4 from "../assets/harshita.jpg";

export const testimonialsData = [
  {
    image: image1,
    review:
      "I am a share holder in this company as well as a member I like the services they offered and I can clearly see the change in my body Keep it up!!!!",
    name: ' CHIRAG',
    status : 'ENTREPRENEUR'
  },
  {
    image: image2,
    review: 'Its my pleasure to be a part of  Healthy Heart Family and I will try my best to train all the family member of Healthy Heart',
    name: 'ABHAY JAISWAL',
    status: 'TRAINER'
  },
  {
    image : image3,
    review:'I would recommend to go for the Premimun Plan because we waste lot of money on uncesssary things so its just 75 per year I can see improvement in my body clearly',
    name: 'SHIVANSH',
    status: "CUSTOMER"
  },
  {
    image : image4,
    review: 'I would like to recommend the other to be a part of the Healthy Heart and makes your Heart Healthy',
    name: 'HARSHITA',
    status: "CUSTOMER"
  }
];
